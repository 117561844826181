import React from "react"
import Link from "gatsby-link"
import logo from '../img/logo-white-all.png';

const HeroBg = ()=>{

  return (

    <div style={{ height: "120px" }} className="header-hero bg_cover d-lg-flex align-items-center"></div>

  );

}

const Header = ( props ) => {
  
  return (

    <header className="header-area">
      <div className="header-navbar">
        <div className="navbar-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">

                <div className="container-fluid navbar-fluid-container">
                <nav className="navbar navbar-expand-lg container">
                  <Link className="navbar-brand" to="/">
                    <img src={ logo } alt={ props.title } width="180" className="navbar-svg"/>
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <Link activeClassName="active" to="/">Home</Link>
                      </li>
                      <li className="nav-item">
                        <Link activeClassName="active" to="/product">Product</Link>
                      </li>
                      <li className="nav-item">
                        <Link activeClassName="active" to="/api">API</Link>
                      </li>
                      <li className="nav-item">
                        <Link activeClassName="active" to="/company">Company</Link>
                      </li>
                      <li className="nav-item">
                        <Link activeClassName="active" to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </nav>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      { props.component ? props.component : <HeroBg/> }
    </header>
  )

}

export default Header;
